export { indexView as default } from './page/_index.tsx';
// export { default } from '~/views/_index/_index';
import { externalAction, externalLoader } from '~/utils/externalLoader.server';
export const action = externalAction;
export const loader = externalLoader;
import { useTranslation } from 'react-i18next';

import { json, LinksFunction } from '@remix-run/node';
import type { MetaFunction, HeadersFunction } from '@remix-run/node';

// export const headers: HeadersFunction = ({ actionHeaders, errorHeaders, loaderHeaders, parentHeaders }) => {
//   console.log({ loaderHeaders });
//   return {
//     'Access-Control-Allow-Origin': '*',
//     'X-Stretchy-Pants': 'its for fun',
//     // "Cache-Control": loaderHeaders.get("Cache-Control"),
//   };
// };
// export const headers: HeadersFunction = ({
// 	actionHeaders,
// 	errorHeaders,
// 	loaderHeaders,
// 	parentHeaders,
// }) => {
// 	return {
// 		'Cache-Control': 'public, max-age=600, s-maxage=600',
// 	};
// };

export const meta: MetaFunction = ({ data, location }: any) => {
	let { t } = useTranslation(['index']);
	// console.log(location);
	let lang = data.userLanguage === 'de' ? '/' : '/' + data.userLanguage + '/';

	return [
		{
			title: t('routeTitle'),
		},
		{
			name: 'title',
			content: t('routeTitle'),
		},
		{
			property: 'og:title',
			content: t('routeTitle'),
		},
		{
			name: 'twitter:title',
			content: t('routeTitle'),
		},
		{
			name: 'description',
			content: t('routeOther'),
		},
		{
			property: 'og:description',
			content: t('routeOther'),
		},
		{
			name: 'twitter:description',
			content: t('routeOther'),
		},
		{
			property: 'og:url',
			content: 'https://northrock.software' + lang,
		},
		{
			name: 'twitter:url',
			content: 'https://northrock.software' + lang,
		},
		//? https://remix.run/docs/en/main/route/meta-v2#meta-v2
		// {
		//   tagName: "link",
		//   rel: "canonical",
		//   href: "https://remix.run",
		// },
	];
};

//* hero
// import cover from '~/content/images/index_page/NorthRock_Entwicklung.webp';

// import heroVid_mobil from '~/content/videos/northrock_software_introduction_mobil.mp4';
// import heroVid_desktop from '~/content/videos/northrock_software_introduction_desktop.mp4';

// import hero_poster from '~/content/images/index_page/hero_poster2.webp';
import hero_poster from '~/content/images/index_page/hero_poster3.webp';

//* caseStudy1
import clientBayern from '~/content/svg/clients/bayern_staatsregierung.webp';
import case_study_1 from '~/content/images/index_page/case_study_1.webp';

//* caseStudy2
import clientMD from '~/content/svg/clients/mde.svg';
import case_study_2_1 from '~/content/images/index_page/case_study_2.webp';

//* caseStudy3
import clientMaintainBasis from '~/content/svg/clients/maintain_basis.svg';
import case_study_3_2 from '~/content/images/index_page/case_study_3.webp';

//* partners
import clientInput1st from '~/content/svg/clients/input1st.svg';
// import clientMaintainBML from '~/content/svg/clients/maintain_bml.svg';
import clientvideoreality from '~/content/svg/clients/videoreality-v1.webp';
// import clientBayern from '~/content/svg/clients/bayern_staatsregierung.webp';

import clientESA from '~/content/svg/clients/ESA_BIC_Hessen_DS.webp';
import clientSeel from '~/content/svg/clients/seel.webp';
import client711 from '~/content/svg/clients/logo-711.webp';
// import clientYOBO from '~/content/svg/clients/yobo_Logo_schwarz.webp';

//* askExpert
import leon from '~/content/images/index_page/leon_bernard_900.webp';

export const links: LinksFunction = () => {
	return [
		// {
		// 	rel: 'preload',
		// 	href: heroVid_mobil,
		// 	// as: 'video',
		// },
		// {
		// 	rel: 'preload',
		// 	href: heroVid_desktop,
		// 	// as: 'video',
		// },
		// {
		//   rel: 'preload',
		//   href: cover,
		//   as: 'image',
		// },

		{
			rel: 'preload',
			href: hero_poster,
			as: 'image',
			// fetchPriority: 'high',
		},

		{
			rel: 'preload',
			href: clientBayern,
			as: 'image',
		},
		{
			rel: 'preload',
			href: case_study_1,
			as: 'image',
		},
		{
			rel: 'preload',
			href: clientMD,
			as: 'image',
		},
		{
			rel: 'preload',
			href: case_study_2_1,
			as: 'image',
		},
		{
			rel: 'preload',
			href: clientMaintainBasis,
			as: 'image',
		},
		{
			rel: 'preload',
			href: case_study_3_2,
			as: 'image',
		},
		{
			rel: 'preload',
			href: clientInput1st,
			as: 'image',
		},
		{
			rel: 'preload',
			href: client711,
			as: 'image',
		},
		{
			rel: 'preload',
			href: clientvideoreality,
			as: 'image',
		},
		{
			rel: 'preload',
			href: clientESA,
			as: 'image',
		},
		{
			rel: 'preload',
			href: clientSeel,
			as: 'image',
		},

		{
			rel: 'preload',
			href: leon,
			as: 'image',
		},
	];
};
